import axios from "axios";

let getShumei = () => {
  return new Promise((resolve) => {
    axios.post(process.env.VUE_APP_BASE_API + 'ishumei-api/api/v1/shumei/web-param').then(res => {
      if (res.data.code == 1) {
        init(res.data.data, resolve)
      }
    })
  })
}

let init = (params, resolves) => {
  window._smReadyFuncs = [];
  window.SMSdk = {
    // onBoxDataReady: function (boxData) { // 非必填
    //   console.log('此时拿到的数据为boxData或者boxId', boxData);
    // },
    ready: function (fn) {
      fn && window._smReadyFuncs.push(fn);
    }
  };

  // 1. 通用配置项
  window._smConf = {
    organization: params.shumei_org_id, // 必填，组织标识，邮件中organization项
    appId: params.shumei_app_id || params.shumei_default_app_id, // 必填，应用标识，默认传值default，其他应用标识提前联系数美协助定义
    publicKey: params.shumei_web_public_key, // 必填，私钥标识，邮件中publicKey项 
    staticHost: 'static.portal101.cn', // 必填, 设置JS-SDK文件域名，建议填写static.portal101.cn
    protocol: 'https', // 如果使用https，则设置，如不使用，则不设置这个字段 

    // 2. 连接海外机房特殊配置项，仅供设备数据上报海外机房客户使用 
    // 2.1 业务机房在国内
    // 1) 用户分布：国内（默认设置）
    // apiHost:'fp-it.portal101.cn'
    // 2) 用户分布：全球 
    // apiHost:'fp-it-acc.portal101.cn'

    // 2.2 业务机房在欧美（弗吉尼亚）
    // 1) 用户分布：欧美
    // apiHost: 'fp-na-it.portal101.cn'
    // 2) 用户分布：全球
    // apiHost: 'fp-na-it-acc.portal101.cn'

    // 2.3 业务机房在欧美（法兰克福）
    // apiHost: 'api-device-eur.portal101.cn'

    // 2.4 业务机房在东南亚
    // 1) 用户分布：东南亚
    // apiHost:'fp-sa-it.portal101.cn'
    // 2) 用户分布：全球
    // apiHost:'fp-sa-it-acc.portal101.cn'

    // 2.5 私有化特殊配置
    // staticHost: 'xxxxxx' // 私有化客户自己引入线上cdn地址，此项必填；如果客户本地引入js文件，此项不填
    // apiHost: 'xxxxxx';  // 私有化部署的服务域名
  };

  var url = (function () {
    var isHttps = 'https:' === document.location.protocol;
    var protocol = isHttps ? 'https://' : 'http://';
    var fpJsPath = '/dist/web/v3.0.0/fp.min.js';
    var url = protocol + window._smConf.staticHost + fpJsPath;
    return url;
  })();
  var sm = document.createElement('script');
  var s = document.getElementsByTagName('script')[0];
  sm.src = url;
  s.parentNode.insertBefore(sm, s);

  resolves()
}

export default getShumei