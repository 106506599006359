import request from "../utils/request";
// import axios from "axios";

export function sendCode(data) {
  return request({
    url: '/user-api/api/v1/user/send_code',
    method: "post",
    data: data,
  })
}

export function userLogin(data, Mid) {
  return request({
    url: '/user-api/api/v1/user/login',
    method: "post",
    data: data,
    headers: {
      Mid
    }
  })
}
