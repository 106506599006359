<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link>
      <router-link to="/about">About</router-link>
    </nav> -->
    <router-view/>
  </div>
</template>
<script>
// import { Toast } from 'vant'
export default {
  name: 'App',
  // data() {
  //   return {
  //     clientHeight: document.documentElement.clientHeight || document.body.clientHeight
  //   }
  // },
  // created () {
  //   window.addEventListener('focusout', function() {
  //     Toast(document.documentElement.clientHeight || document.body.clientHeight, this.clientHeight)
  //   })
  // },
}
</script>
<style lang="scss">
* {
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #404147;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

div {
  box-sizing: border-box;
}

// 阿里字体图标设置
.icon, .iconfont {
  font-family:"iconfont" !important;
  font-size:16px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
</style>
