import {
  USER_LOGIN,
  INIT_USER,
  LOGIN_OUT
} from './types'

export default {
  state: {
    token: null, // token
  },
  getters: {},
  mutations: {
    // 设置
    [USER_LOGIN](state, user) {
      state.token = user.token
      state.userInfo = user
      
      // 持久化存储-->本地存储
      localStorage.setItem('userInfo', JSON.stringify(user))
      localStorage.setItem('token', user.token)
    },
    // 读取
    [INIT_USER](state) {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if (userInfo) {
        state.token = userInfo.token
        state.userInfo = userInfo
      }
    },
    // 退出登录
    [LOGIN_OUT](state) {
      state.token = null
      state.userInfo = {}
      localStorage.removeItem('userInfo')
    }
  },
  actions: {}
}