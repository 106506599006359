import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Bridge from "@/assets/js/dsbridge.js";
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// 全局引入vant
import Vant from 'vant'
import 'vant/lib/index.css'
Vue.use(Vant)

Vue.config.productionTip = false
Vue.prototype.$bridge = Bridge;
Vue.use(Element);

// 全局引入iconfont
import './assets/fonts/iconfont/iconfont.css'

import date from '@/utils/date'
Vue.use(date)

import './utils/web_sdk'

// 图片展示/存储地址
Vue.prototype.$linkUrl = process.env.VUE_APP_BASE_OSS

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
